import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import Recaptcha from 'react-google-recaptcha'
import Helmet from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
        .join(`&`)
}

const RECAPTCHA_KEY = `6LcUDPsUAAAAABYTq4OD2Xj-au6OaxF7X7nLUbM6`

if (typeof RECAPTCHA_KEY === `undefined`) {
    throw new Error(`
        Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
        You probably forget to set it in your Netlify build environment variables. 
    `)
}

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const [state, setState] = React.useState({})
    const recaptchaRef = React.createRef()

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        const recaptchaValue = recaptchaRef.current.getValue()
        if (!recaptchaValue) {
            alert(`Invalid Captcha`)
            return
        }
        fetch(`/`, {
            method: `POST`,
            headers: { "Content-Type": `application/x-www-form-urlencoded` },
            body: encode({
                "form-name": form.getAttribute(`name`),
                url: window.location.href,
                'g-recaptcha-response': recaptchaValue,
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute(`action`)))
            .catch(error => alert(error))
    }
    const post = data.ghostPost

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <article className="content">
                        { post.feature_image ?
                            <figure className="post-feature-image">
                                <img src={ post.feature_image } alt={ post.title } />
                            </figure> : null }
                        <section className="post-full-content">
                            <h1 className="content-title">{post.title}</h1>

                            {/* The main post content */ }
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                    <div className="YourThoughts">
                        <div className="divider"></div>
                        <form
                            name="feedback"
                            method="post"
                            action="/thank-you-for-reading"
                            data-netlify="true"
                            data-netlify-recaptcha="true"
                            onSubmit={handleSubmit}                            
                        >
                            <noscript>
                                <p>This form won’t work with Javascript disabled</p>
                            </noscript>
                            <div className="formHeader">
                                Leave a feedback 
                            </div>
                            <p>
                                Jika ingin bertanya atau memberi tanggapan seputar tulisan ini, bisa menjangkau saya melalui kolom berikut.
                            </p>
                            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                            <input type="hidden" name="form-name" value="feedback" />
                            <input type="hidden" name="url" value="" />
                            <div>
                                <label>Your thoughts *&nbsp;&nbsp;&nbsp;(min. 10 characters)</label>
                                <br />
                                <textarea name="thoughts" onChange={handleChange} required minLength="10"/>
                            </div>
                            <div>
                                <label>Email *</label>
                                <br />
                                <input type="email" name="email" onChange={handleChange} required/>
                            </div>
                            <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} className="reCaptcha"/>
                            <button type="submit">Send</button>
                        </form>
                    </div>
                </div>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
